//if(typeof(console)=="undefined") console={log:function(a){}};
window.USER={
	startPanel:function(){
		var c=$.cookie("hidepanel");
		if(c==1) USER.hidePanel();
	},
	hidePanel:function(){
		$('#userPanel').addClass('hidden');
		$.cookie('hidepanel','1',{expires:10,path:"/"});
		console.log("hidepanel "+$.cookie("hidepanel"));
	},
	showPanel:function(){
		$("#userPanel").removeClass("hidden");
		$.cookie('hidepanel',null,{expires:1,path:"/"});
		console.log("hidepanel "+$.cookie("hidepanel"));
	}
}

svgsupport=document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#BasicStructure", "1.1");

animation={
	elements:[null],
	duration:860,
	from:null,
	createNew:function(to){
		if($(to).size()==0) return false;
		var coin=$("<img class='freecoin' style='opacity:0;top:0;left:0;' src='/assets/fsimages/logo.svg"+(!svgsupport?".png":"")+"'>");
		$('div.wrap').append(coin);
		this.from=$("#header .logo img").offset();
			
		for(i=0;i<(this.elements.length+1);i++){
			var T=new Date();
			if(!this.elements[i]){
				this.elements[i]={
						start:T.valueOf(),
						target:coin,
						to:to.offset(),
						interval:setInterval("animation.update("+i+");",30)
					};
				animation.update(i);
				break;
			}
		}
		
	},
	update:function(i){
		var T=new Date(), time=T.valueOf()-this.elements[i].start;
		var k=time/this.duration;
		this.elements[i].target.css({
				top:this.from.top+(this.elements[i].to.top-this.from.top)*k-150*Math.sin(Math.PI*k),
				left:this.from.left+(this.elements[i].to.left-this.from.left)*k-90*Math.sin(Math.PI*k),
				width:147*(1-0.75*k),
				height:147*(1-0.75*k),
				transform:"rotate(-"+k*360+"deg)",
				opacity:Math.pow(Math.sin(Math.PI*k),0.4)
			});
		
		
		if(time>this.duration){
			KARMA.addSomeKarma(0.02);
			this.destroy(i);
			return;
		}
		
		
	},
	destroy:function(i){
		this.elements[i].target.remove();
		clearInterval(this.elements[i].interval);
		this.elements[i]=null;
	}
}

KARMA={
	value:0.1,
	hourse:240,
	init:function(){
		this.getCookie();
		setInterval(KARMA.tick,2000);
	},
	addSomeKarma:function(v){
		this.getCookie();
		this.value+=v;
		this.update();
	},
	update:function(){
		$("div.karma .value, span.karmavalue").text(this.value.toFixed(2));
		$("div.karma").addClass('shine');
		setTimeout("$('div.karma').removeClass('shine');",400);
		$.cookie('karma',(this.value.toFixed(2)+","+(new Date()).valueOf()),{expires:60,path:"/"});
		if(minkarma=$(".karmaneeded").text()) {
			if(this.value>=parseFloat(minkarma)) location.reload();
		}
	},
	tick:function(){
		KARMA.getCookie();
		//if(KARMA.value>0.1) KARMA.value-=0.01;
		KARMA.update();// this. not work!!
	},
	getCookie:function(){
		var k=$.cookie("karma")?$.cookie("karma"):"";
		var t;
		if(k){
			//console.log("Get carma: "+$.cookie("karma"));
			this.value=parseFloat(k.split(",")[0]);
			if(this.value<0.1 || isNaN(this.value)) this.value=0.1;
			
			t=parseInt(k.split(",")[1]);
			this.value-=((new Date()).valueOf()-t)/2000*0.01;
			
			if(this.value<0.1)this.value=0.1;
		} else {
			this.value=0.1;
		}
		this.update();
	}
}

function rotatorChange(){
	var s=$(".rotator .r-control span");
	var a=s.filter(".active").attr("n");
	a++;
	a=a>=s.size()?0:a;
	s.filter(":eq("+a+")").click();
}
function rotator(){
	var active=0;
	var r=$(".rotator");
	var c=r.find(".rot-body a").size();
	r.find(".rot-body a").hide().filter(":eq("+active+")").show();
	for(i=0;i<c;i++) {
			r.find(".r-control").append("<span n='"+i+"'></span>");
			//.data("n",i);
		}
	r.find(".r-control span:eq("+active+")").addClass("active");
	r.find(".r-control span").click(function(e){
		r.find("span.active").removeClass("active");
		$(this).addClass("active");
		r.find(".rot-body a:eq("+active+")").fadeOut(200);
		active=$(this).attr("n");
		r.find(".rot-body a:eq("+active+")").fadeIn(200);
		e.preventDefault();
		return false;
	})
	var interval=setInterval(rotatorChange,2200);
}


mainSliderInterval=null;
function mainSliderChange(minus){
	var s=$(".main-slider .r-control span");
	var a=s.filter(".active").attr("n");
	minus?a--:a++;
	a=a>=s.size()?0:a;
	a=a<0?(s.size()-1):a;
	s.filter(":eq("+a+")").click();
}
function mainSlider(){
	var active=0;
	var r=$(".main-slider");
	if(r.size()==0) return;
	var c=r.find(".rot-body a").size();
	r.find(".rot-body a").removeClass("active").filter(":eq("+active+")").addClass("active");
	for(i=0;i<c;i++) {
			r.find(".r-control").append("<span n='"+i+"'></span>");
			//.data("n",i);
		}
	r.find(".r-control span:eq("+active+")").addClass("active");
	r.find(".r-control span").click(function(e){
		r.find("span.active").removeClass("active");
		$(this).addClass("active");
		r.find(".rot-body a:eq("+active+")").removeClass("active");
		active=$(this).attr("n");
		r.find(".rot-body a:eq("+active+")").addClass("active");
		e.preventDefault();
		return false;
	});
	r.find(".r-control-left").click(function(){
		mainSliderChange(true);
		clearInterval(mainSliderInterval);
		mainSliderInterval=setInterval(mainSliderChange,4400);
	});
	r.find(".r-control-right").click(function(){
		mainSliderChange();
		clearInterval(mainSliderInterval);
		mainSliderInterval=setInterval(mainSliderChange,4000);
	});
	
}


$().ready(function(){
	widthCheck();
	
	$('#checker').attr('value', 'hight');
	
	
	userPanel();
	
	gamesFilter();

	$(window).resize(function(){
		widthCheck();
		winPositionCheck();
	});
	winPositionCheck();
	
	playerRating();
	
	if( $('body').hasClass('game') && !$("#iframeBox").hasClass("fixsize") ) Game.init();
	if($("#iframeBox").hasClass("fixsize")) {
		$(".center").css({"min-width":"800px",height:"673px"});
		$(".body").addClass("fix");
	}
	
	$(window).scroll(function(){
		if($('body').width()>(1270)){
			winPositionCheck();
		}
	});
	
	
	
	$("div.win-social .close, div.win-say .close").click(function(){
		$(this).parent().fadeOut(300);
	});
	
	$(".social li, .cointarget").bind('mouseenter mousedown',function(){
		animation.createNew($(this));
	});
	
	setTimeout("animation.createNew($('.cointarget:eq(0)'));", 1200);
	setTimeout("animation.createNew($('.cointarget:eq(1)'));", 2800);
	
	KARMA.init();
	
	if($.cookie("ref")!=location.pathname){
		KARMA.addSomeKarma(0.77);
	}
	$.cookie("ref",location.pathname,{expires:2,path:"/"});
	
	$(".social li").click(function(){
		//console.log("karma added");
		if($(this).hasClass("clicked")) return true;
		$(this).addClass("clicked");
		KARMA.addSomeKarma(15);
		
	});
	
	casinoSlider();
	rotator();
	mainSlider();
	suscribeForm();
	socialButtons(); // включаем социалки
	
	getLastPlayedGames();
	
	Commenting();
	
	var compBody=$("div.comp-body");
	$(".complaint").click(function(){
			compBody.fadeIn(300);
		});
	$("div.comp-form .close").bind("click",function(){
			compBody.hide();
		});
	compBody.find(".submit").bind("click",function(){
			var m=compBody.find("textarea").val();
			if(m.length<1) return false;
			$.post("/ajax.php",{action:"complaint",c:m.length,message:m},function(data){
				if(data=="sended"){
					compBody.find(".sended").show(400);
					setTimeout("$('div.comp-body').fadeOut(250);$('div.comp-body .sended').hide();",2600);
					KARMA.addSomeKarma(3);
					compBody.find("textarea").val("");
				}else{
					compBody.hide();
				}
			});
		});
	
});

$(window).load(function(){
	randomSlotMachine();
});





function widthCheck(){// check if window width is to low for social buttons block
	var c=$("div.win-social,div.win-say");
	if($('body').width()>(1260)){
		winPositionCheck();
		c.addClass('fixed');
	} else {
		c.removeClass('fixed');
	}
}
function winPositionCheck(){
	c=$("div.win-social, div.win-say");
	if($(window).scrollTop()>314){
			c.css({position:'fixed',top:'25'});
			c.filter("div.win-social").css({left:$("#body").offset().left-135});
			c.filter("div.win-say").css({right:"auto",left:$("#body").offset().left+$("#body").width()+36});
		} else {
			c.css({top:'38px',position:'absolute'});
			c.filter("div.win-social").css({left:"-135px"});
			c.filter("div.win-say").css({right:"-135px",left:"auto"});
		}
}

function runCoinDropEffect(target){
	var coin=$("<img class='freecoin' src='/assets/fsimages/f-slots.png'>"),
		from=$("#header .logo img").offset(),
		to=target.offset();
	$('div.wrap').append(coin);
	animation.createNew(coin);
}



var timeout;
function userPanel(){
	var game=$("body").hasClass('game');
	if(!game) {
			$("#userPanel").removeClass("hidden");
			USER.startPanel();
		}
	if(game) $("#userPanel").mousemove(function(){
		//console.log(timeout);
		if(timeout) clearTimeout(timeout);
		timeout=setTimeout(USER.hidePanel,5000);
	});
	$("#userPanel").click(function(e){
		if($("#userPanel").hasClass("hidden")){
			USER.showPanel();
			if(game){
					clearTimeout(timeout); timeout=setTimeout(USER.hidePanel,5000);
				}
			e.preventDefault();
			return false;
		}
	});
	$("#userPanel .close").click(function(e){
			if(!$("#userPanel").hasClass("hidden")){
				USER.hidePanel();
				e.preventDefault();
				return false;
			}
	});
	if($("#userPanel li").size()==0) $("#userPanel").addClass("hidden");// hide on start
}


Game = {
	center:null,
	init:function(){
		var self=this;
		this.center=$("div.body>.center");
		
		self.onResize();
		setTimeout("Game.onResize();",10);
		$(window).resize(function(){
			self.onResize();
		});
		
		$("#header, div.left, div.karma, div.game-social").addClass("fade");
	},
	onResize:function(){
		var winh=$(window).height();
		var winw=$(window).width();
		//var h=this.center.width()/747*640;
		h=winh-$("#header").height()-10;
		if(h<520) h=520;
		
		var max=h*1.4+640;
		max=max>1900?1900:max;
		max=max<994?994:max;
		
		h=(max-640)/1.4;
		
		this.center.css('height',this.center.width()/1.25);
		
		if($.browser.msie) { 
			if(winw<1400) $("body").addClass('smallmode'); else $("body").removeClass('smallmode'); 
		}
	}
}


function casinoSlider(){
	sliderCounter=0;
	var total=$("div.casino-slider li").size();
	$("div.casino-slider li:gt(0)").hide();
	if(total==1){
		$("div.casino-slider .left,div.casino-slider .right").hide();
	} else 
	$("div.casino-slider .left,div.casino-slider .right").click(function(){
		$("div.casino-slider li:eq("+sliderCounter+")").fadeOut();
		sliderCounter++;
		if(sliderCounter>=total) sliderCounter=0;
		if(sliderCounter<0) sliderCounter=total-1;
		$("div.casino-slider li:eq("+sliderCounter+")").fadeIn();
	});
	setInterval("$('div.casino-slider .left').click();", 5000);
	
}


function randomSlotMachine(){
	var m=$(".randomSlotMachine");
	m.show();
	m.find(".trigger").mousedown(function(e){
		m.addClass("click");
		setTimeout("$('.randomSlotMachine').removeClass('click');",400);
		$.post("/engine_ajax.php",{action:"getrandomslot"},function(data){
			//console.log(data.u);
			var a=m.find(".machinebody a");
			if(a.size()){
				a.stop(0,1).animate({
					opacity:0,
					top:"142px"
				},500,function(){
					randomSlotMachineNew(data);
				});
			} else {
				randomSlotMachineNew(data);
			}
		}, "json");
		e.preventDefault();
		return false;
	});
	setTimeout("$('.randomSlotMachine .trigger').mousedown();",1500);
	function randomSlotMachineNew(slot){
		m.find(".machinebody").html('<a href="'+slot.u+'"><img src="'+slot.i+'" alt="'+slot.n+'"><span class="playes">'+slot.c+'</span></a>');
		m.find(".machinebody a").css({opacity:0,top:"-142px"}).animate({
				opacity:1,
				top:0
			},400);
	}
}

function getLastPlayedGames(){
	$.post(
		"/engine_ajax.php",
		{action:"getslots"},
		function(data){ if(data) $("#userPanel ul").html(data); }
	);
}

function Commenting(){
	$(".comments .com-form").submit(function(){
		var f=$(this);
		var txt=f.find("textarea").val();
		if(txt.length>12){
			txt=txt.replace("  "," ");
			KARMA.addSomeKarma(txt.split(" ").length*0.05);
		}
		$.post("/engine_ajax.php",
			{
				action:"commenting",
				message:f.find("textarea[name=message]").val(),
				username:f.find("input[name=username]").val(),
				email:f.find("input[name=email]").val(),
				website:f.find("input[name=website]").val(),
				notifyme:f.find("input[name=notifyme]").val(),
				checker:f.find("input[name=checker]").val(),
				docid:f.find("input[name=docid]").val(),
				reply:f.find("input[name=reply]").val()
			},
			function(data){
				if(data=="accepted"){
					f.find(".warning").html("<big style='color:red;'>Ваш комментарий добавлен и появится после быстрой модерации</big>");
					f.find("textarea[name=message]").val("");
					f.find(".leftform, .rightform").remove();
				} else if(data=="accepted-reply"){ 
					location.reload();
					//alert("reply");
				} else {
					f.find(".warning").html("Заполните все поля правильно");
				}
			});
		return false;
	});
}
function feedbackForm(){
	$("form.feedbackForm").submit(function(){
		var f=$(this);
		$.post("/engine_ajax.php",
			{
				action:"feedback",
				site:"",
				message:f.find("textarea[name=message]").val(),
				username:f.find("input[name=username]").val(),
				email:f.find("input[name=email]").val()
			},
			function(data){
				f.html(data);
				f.find("textarea[name=message]").val("");
			});
		return false;
	});
}

function suscribeForm(){
	var s=$(".subscribe");
	s.find(".submit").click(function(){
		$.post("/engine_ajax.php",{subscribe:"true",email:s.find("input[name=email]").val()},
			function(data){
				if(data==1){
					s.find(".info").addClass("message").html("<br>Подписка оформлена!");
					//s.find("input, .submit").hide();
				}else if(data==2){
					s.find(".info").addClass("message").html("<br>Вы уже подписаны!");
					//s.find("input, .submit").hide();
				}else {
					s.find(".info").addClass("message").html("Неверный email!");
				}
			}
		);
		return false;
	});
}



function gamesFilter(){
	$(".slotfilter").each(function(i,k){
		$(this).find(".sorting").click(function(){
			var dir=$(this).attr('sortdir');
			switch($(this).attr('sort')){
				case 'alphabetic':
					filterSort("ul.slotlist[fid="+$(k).attr("fid")+"]",".title",dir,"word");
				break;
				case 'played':
					filterSort("ul.slotlist[fid="+$(k).attr("fid")+"]",".playes",dir,"dig");
				break;
				case 'date':
					filterSort("ul.slotlist[fid="+$(k).attr("fid")+"]","",dir,"date");
				break;
			}
			$(k).find(".active").removeClass("active");
			$(this).addClass("active");
			return false;
		});
	});
}

function filterSort(which,where, dir, type) {
	dir = (dir == "desc") ? -1 : 1;
	$(which).each(function() {
		var sorted = $(this).find(">li").sort(function(a, b) {
			if(type=="dig") return parseInt($(a).find(where).text())>parseInt($(b).find(where).text())?dir:-dir;
			if(type=="date") return parseInt($(a).attr('date'))>parseInt($(b).attr('date'))?dir:-dir;
			return $(a).find(where).text().toLowerCase() > $(b).find(where).text().toLowerCase() ? dir : -dir;
		});
		$(this).html(sorted);
	});
}





function socialButtons(){
	$('.like-buttons .buttons').one("mouseover",function() {
		$(this).removeClass("social-fake");
		console.log("over");
		//if ( location.host != 'hi-news.local' ) {
		  var url = "";//$(this).attr("data-url");//$(this).data('href');
		  var title = "";//$(this).attr("data-title");//$(this).data('title');
		  //$(this).removeClass('social-panel-inactive').addClass('social-panel-active');
		  $(this).html(SocialWidgets.getCode(url, title));
		//}
		return false;
	});
}


(function() {	
	
	var SocialWidgets = function() {
		var _vk_cnt = 0;
		var _state = {twitter: 0, fb: 0, vk: 0, gp: 0};
		
		this.getCode = function(url, title) {
			var code = '';
			//code += '<div class="sw-btn sw-twitter">' + this.getTWcode(url, title) + '</div>';
			code += '<div class="mail-like">' + this.getMailcode(url) + '</div>';
			code += '<div class="od-like">' + this.getODcode(url) + '</div>';
			code += '<div class="vk-like">' + this.getVKcode(url, title) + '</div>';
			code += '<div class="fb-like">' + this.getFBcode(url) + '</div>';
			code += '<div class="gp-like">' + this.getGPcode(url) + '</div>';
			return code;
		}
		
		this.getMailcode = function(url, title) {
			var code = '<a target="_blank" rel="external nofollow" class="mrc__plugin_uber_like_button" href="http://connect.mail.ru/share" data-mrc-config="{\'nt\':\'1\',\'cm\':\'1\',\'sz\':\'20\',\'st\':\'2\',\'tp\':\'mm\'}">Нравится</a>'
				+'<script async src="http://cdn.connect.mail.ru/js/loader.js" type="text/javascript" charset="UTF-8"></script>';
			return code;
		};
		this.getODcode = function(url, title) {
			var code = '<div id="ok_shareWidget"></div>'
				+'<script>!function (d, id, did, st) {var js = d.createElement("script");js.src = "http://connect.ok.ru/connect.js";js.async = true;js.onload = js.onreadystatechange = function () {'
				+'if (!this.readyState || this.readyState == "loaded" || this.readyState == "complete") {'
				+'if (!this.executed) {this.executed = true;setTimeout(function () {OK.CONNECT.insertShareWidget(id,did,st);}, 0);}}};d.documentElement.appendChild(js);'
				+'}(document,"ok_shareWidget",document.URL,"{width:100,height:30,st:\'rounded\',sz:20,nt:1}");'
				+'</script>';
			return code;
		};
		
		this.getTWcode = function(url, title) {
			var code = '<a href="https://twitter.com/share"';
			if(url) code += ' data-url="' + url + '"';
			if(title) code += ' data-text="' + title + ' (via @Hi_News_ru)"'; //  с помощью
			code += ' class="twitter-share-button">Tweet</a>';
			code += '<script src="http://platform.twitter.com/widgets.js" type="text/javascript"></script>';
			return code;
		};
		this.getVKcode = function(url, title) {
			var code = '';
			if(!_state.vk) {
				_state.vk = true;
				code += '<script type="text/javascript">VK.init({apiId: 4885476, onlyWidgets: true});</script>';//com - 4885476, net - 4788547
			}
			_vk_cnt = _vk_cnt + 1;
			
			code += '<div id="vk_like_' + _vk_cnt + '"></div>';
			code += '<script type="text/javascript">VK.Widgets.Like("vk_like_' + _vk_cnt + '", {type: "button", height: 20';
			if(url) code += ', pageUrl: "' + url + '"';
			if(title) code += ', pageTitle: "' + title + '"';
			code += '});</script>';
			return code;
		};
		this.getFBcode = function(url, title) {
			var code = '<fb:like layout="button_count" show_faces="false" style="width: 130px;" width="130"';
			if(url) code += ' href="' + url + '"';
			code += '></fb:like>';
			code += '<script>try{ FB.XFBML.parse(); } catch(ex){}</script>';
			return code;
		};
		this.getGPcode = function(url) {
			var code = '<div class="g-plusone" data-size="medium"';
			if(url) code += ' data-href="' + url + '"';
			code += '></div>';
			code += '<script type="text/javascript" src="https://apis.google.com/js/plusone.js"></script>';
			return code;
		};
	};
	window.SocialWidgets = new SocialWidgets();
})();





/*
-------------- Код для Рейтинга игроков
*/

function playerRating(){
	var rating=$(".gamestat");
	$(".showRating").click(function(){
		if(!rating.data("visible")){
			rating.data("visible",true);
			rating.fadeIn(200);
			getPlayersRating();
		}
		return false;
	})
	rating.find(".close").click(function(){
		rating.data("visible",false);
		rating.fadeOut(200);
	});
	
	
	$("button.stat-logout").click(function(){
		$.post("/engine_ajax.php",{action:"logout"},function(data){
			if(data){
				if(data.logout){
					$(".stat-user").hide();
				}
			}
		},"json");
	})
	
	
	if(rating.attr("authorized")=="true") startRatingCounting();
	
	$("button.stat-loginf").click(function(e){
		$(".stat-reg").hide();
		$(".stat-login").show();
		e.preventDefault();
	});
	$("button.stat-regf").click(function(e){
		$(".stat-login").hide();
		$(".stat-reg").show();
		e.preventDefault();
	});
	$(".stat-user .stat-submit").click(function(){
		//Проверить все ли поля заполнены
		var _t=$(this),parent=_t.parents(".stat-form");
		var valid=true;
		parent.find("input").each(function(k,t){
			if($(t).attr("name")=="name") {
				if($(t).val().length<3){
					valid=false;
					$(t).addClass("notvalid");
				}
			} else if($(t).val().length<6){
				valid=false;
				$(t).addClass("notvalid");
			}
		})
		var name=parent.find("input[name=name]").val();
		var email=parent.find("input[name=email]").val();
		var pass=parent.find("input[name=pass]").val();
		console.log(name+" "+pass);
		if(valid){
			$.post("/engine_ajax.php",
				{
					action:_t.attr("action"),
					name:name,email:email,pass:pass
				},
				function(data){
					if(!data) data={error:"Ошибка"};
					if(data) {
						if(data.login || data.register){
							$(".stat-user").html('Вы участвуете в рейтинге под именем <b class="marked">'+$.cookie("name").split(':')[0]+'</b>');
							startRatingCounting();
						} else {
							parent.find(".message").text(data.error);
						}
					}
					
				},"json");
		}
	})
}
function startRatingCounting(){
	updatePlayerRating();
	setInterval(updatePlayerRating,20000);
}
function updatePlayerRating(){
	$.post("/engine_ajax.php",
       {action:"updateRating",gameId:$(".gamestat").attr("gameId")},
       function(data){ 
         console.log("update rating: "+data);
       });
}
function getPlayersRating(){
	$(".gamestat .stat-holder tbody").html("<tr><td colspan=4>Обновление</td></tr>");
	$.post("/engine_ajax.php",
		{action:"getgamestat",gameId:$(".gamestat").attr("gameId")},
		function(data){
			$(".gamestat .stat-holder tbody").html(data);
			$(".gamestat .stat-holder tbody td").each(function(k,o){
				if( $(o).html()==$(".stat-user b") ) $(o).parent().addClass("userstat");
			});
		});
}
