var gamesUrl = 'https://slotsplay.app/game_json';

function loadIframeError(is_mobile, iframeBlock) {
        $('.btn-startgame').hide();
}

function adBlock() {
    return false;
}

function iframeSize(ratio) {

	ratio = ratio ? ratio : 1.33;

	var iframeWin = $('.game_block');

	if (iframeWin.length > 0) {
    	
    	iframeWin.height('100%');
		iframeWin.width(iframeWin.height() * ratio);
	}
}


function gameLoadMobile(data, iframeBlock) {

	var error = 0;
		
	if (data.mobile === undefined || data.mobile.direct_url === undefined) {
		error = 1;
		return error;		
	}

    $('.btn-startgame').attr('href', data.mobile.direct_url);
    $('.btn-startgame').attr('target', '_blank');
	$('.btn-startgame').text('Играть | Play');

	$('.btn-startgame').on('click', function() {
		
		
		
	});

	return error;
}

function gameLoadDesktop(data, iframeBlock) {

	var error = 0;

	if (data.frame === undefined) {
		error = 1;
		return error;		
	}

	$('.btn-startgame').text('Играть | Play');

	$('.btn-startgame').on('click', function() {
        
        $('.modalGamePlay').show();
		$('.game_block').html(data.frame);
		iframeSize(eval(data.dimensions.replace(":","/")));
		return false;

	});
	
	return error;
	
}


function gameLoad() {

	var iframeBlock = $('.modalGamePlay');
	var iframeWidth =  $('.modalGamePlay').height();
	var iframeHeight = $('.modalGamePlay').width();
	var mobile = iframeBlock.data('mobile');

    $('.modalGamePlay .close').on('click', function() {
        
       $('.game_block').html('');
       $('.modalGamePlay').hide();
        
        
    });



	var params = {
		name:  iframeBlock.data('name'),
		country: 'AUTO',
		width: iframeWidth,
		height: iframeHeight,
		is_casino: 1,
		has_adblock: 0
	};

	$.ajax({
		type: 'POST', // sending as post
		url: gamesUrl, // games Handler
		dataType: "json",

		xhrFields: {
		    withCredentials: true
		},

		data: params, // дaнныe для oтпрaвки
		success: function(data) {
    		
			var errorIframe = 0;

			if (mobile) {
				errorIframe = gameLoadMobile(data, iframeBlock);
			} else {
				errorIframe = gameLoadDesktop(data, iframeBlock);
			}
			
			if (errorIframe == 1) {
				loadIframeError(mobile, iframeBlock);
			}
			
		}

	}).fail(function() {			
		loadIframeError(mobile, iframeBlock);
	});	
}

    gameLoad();

