function iniFrame() { 

    if ( window.location !== window.parent.location)  { 
      
      return true;
      
    }  else { 
          
      return false;

    } 

}

if (iniFrame()) {
    window.top.location.href = window.location.href; 
}

